import 'src/pages/404/index.scss';
import Icon from 'src/components/atoms/icon';
import { Link } from 'gatsby';
import React from 'react';

export default () => {
  const message = 'We couldn\'t find that page. Please check the URL and try again.';

  return (
    <div className='page-404'>
      <Icon type='noResults'/>
      <div className='page-404--message'>{message}</div>
      <Link to='/'>Go Home</Link>
    </div>
  );
};